

import React,{useState} from 'react';

export default function CardNosotros(props) {
    const [hover, setHover] = useState(0);
    const [bright, setBright] = useState('100%');
    const onHover = (p) => {
        if(p === true){
            setBright('30%');
            setHover(1);
        }else{
            setBright('100%');
            setHover(0);
        }
    }
    return (
        <div  className='noselect' id='container-card' onMouseEnter={() => onHover(true)} onMouseLeave={() => onHover(false)} >
            
            <img src={props.image} style = {{opacity:bright, transition:'opacity 0.9s', }} width = {'100%'} alt="card" id='card' />
            
            <p id='card-text' style={{opacity:hover, transition: 'opacity 0.5s'}}>{props.texto}</p>
        </div>
    );
}
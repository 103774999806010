import Image from "../../images/2_header.png"
import './header.css'
import Slide from "@mui/material/Slide";
export default function Header() {
    return (
        
        <header className="header noselect"  >
            <div width="100%" id='container'>
                <img src={Image} alt="imageheader" className="image" />
                
                <div id='header-text'>
                <Slide direction="down" in={true} timeout={1200} >
                    
                <p id="white-text" >ROLAND IMPRESORES</p>
                </Slide>   
                <Slide direction="left" in={true} timeout={1200} >
                <hr id='hr' />
                </Slide>
                <Slide direction="up" in={true} timeout={1200} >
                <p id='white-text' >Seguridad y Tecnología en tu impresión</p>
                </Slide>
                            
                </div>

               
            </div>
        </header>

    );
}

import Info from './Info'
import Card from './Card'
import './Tecnologia.css'
export default function Tecnologia() {
    
    return(
        <div id='contenedor-tech'> 
        <div className="title-tech">
            <h1 id="titulo">Tecnologías</h1>
        </div>
            
        <div>
            {Info.info.map((item,key) => (
                    <div key={key} id="contenedor-techcard">
                             <Card img= {item.img} title ={item.title} descripcion={item.descripcion} />
                    </div>
                ))
            }
        </div>  
            
                    
        </div>

    )
}
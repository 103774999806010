import { Typography, Grid } from "@material-ui/core/";
import BusinessTwoToneIcon from "@material-ui/icons/BusinessTwoTone";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import DataUsageTwoToneIcon from "@material-ui/icons/DataUsageTwoTone";
import './footer.css'
export default function footer() {
  return (
    <div id="container-footer">
      <Grid style={{ color:'#230635'}}>
        <Grid
          container
          style={{
            justifyContent: "space-around",
            color: "black",
            padding: "1%",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Grid>
            <PhoneInTalkIcon />
            <Typography> PBX: (57 -1 )383 84 62</Typography>
          </Grid>
          <Grid>
            <BusinessTwoToneIcon />
            <Typography>Cra. 28A No. 74 - 59 </Typography>
            <Typography>Bogotá, Colombia.</Typography>
          </Grid>
          <Grid style={{ justifyContent: "space-between" }}>
            <a href="mailto:info@rolandimpresores.com">              
            <TwitterIcon />
            <FacebookIcon />
            <InstagramIcon />
            </a>
            <Typography>Siguenos en nuestras redes</Typography>
          </Grid>
          <Grid>
            <DataUsageTwoToneIcon />
            <Typography>Politica para el tratamiento de datos </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

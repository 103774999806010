import "./App.css";
import MainPage from "./MainComponents/MainPage";
import Sesion from "./MainComponents/Sesion/Sesion";


import { BrowserRouter as Router, Route, Routes } from "react-router-dom";




function App() {
  return (
    <div>

      <Router>
     
        <Routes>
          <Route exact path="/" element={<MainPage/>} />
          <Route exact path="/sesion" element={<Sesion/>} />
          <Route path = "*" element={<MainPage/>} />
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;

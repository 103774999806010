const info = {
    info: [
      {
        title: "Intaglio",
        descripcion: "Te brindamos seguridad y tecnología en nuestros impresos,  con las tintas de mayor calidad y una impresión acorde a tus pedidos",
        img: "../assets/tecnologias/1_tech.png"
        },
        {
        title: "Impresión",
        descripcion: "La calidad de la impresión es nuestra prioridad, brindamos siempre la mejor impresión de tus diseños",
        img: "../assets/tecnologias/2_tech.png"
        },
        {
        title: "Tecnología",
        descripcion: "Nuestra tecnología es la mejor para brindarle la mejor calidad a tus diseños, con los mejores materiales",
        img: "../assets/tecnologias/3_tech.png"
        },
    ]
}
export default info;

        



import Tilt from 'react-tilt';
import './Tecnologia.css'

export default function Card(props) {
  return (
      
  <div className="align">    
    <div className="rowp">
      <div >
        <Tilt >
            <img className="img" src={props.img} width={'60%'} alt="" />
        </Tilt>
        
      </div>
      <div className="column-2 text-card-tech">
        <div id="card-text ">
          <h3>{props.title}</h3>
        </div>
        <div id="card-text ">
          <p>{props.descripcion}</p>
        </div>
      </div>
    </div>
    </div>
    
  );
}

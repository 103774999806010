import './nosotros.css'
import us1 from '../../images/1us.png';
import us2 from '../../images/2us.png';
import us3 from '../../images/3us.png';
import Card from './CardNosotros'
export default function Nosotros() {
    const texto = ['Somos una empresa dedicada a la impresión en Intaglio y otros tipos de seguridad', 'Nuestros productos y servicios se basan en la calidad y seguridad', 'Somos una organización responsable y comprometida con nuestros clientes']
    const arr = [us3, us2, us1]
    return (
        <div id='contenedor-nosotros'> 
        <div id="container-text">
            <h1 id="titulo">Nosotros</h1>
            <p id="descripcion">Estamos hace mas de 30 años en el sector gráfico y contamos con profesionales y expertos dedicados a este sector, con una amplia experiencia en el sector, ofreciendo servicios de alta calidad y con una amplia gama de productos.</p>
            </div>
            <div id="row">
          
            {arr.map((item,key) => (
                    <div key={key} id="column">
                        <Card image= {item} texto ={texto[key]} />
                    </div>
                ))
            }
            </div>
                    
        </div>

    );
}